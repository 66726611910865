// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-font-family: "Manrope";
  
  /** primary **/
  --ion-color-primary: #8483b0;
  --ion-color-primary-rgb: 132, 131, 176;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #74739b;
  --ion-color-primary-tint: #908fb8;

  // pesttrapp
  --ion-color-pesttrapp: #8483b0;
  --ion-color-pesttrapp-rgb: 132, 131, 176;
  --ion-color-pesttrapp-contrast: #ffffff;
  --ion-color-pesttrapp-contrast-rgb: 255, 255, 255;
  --ion-color-pesttrapp-shade: #74739b;
  --ion-color-pesttrapp-tint: #908fb8;

  // filiz
  --ion-color-filiz: #4c9a31;
  --ion-color-filiz-rgb: 76, 154, 49;
  --ion-color-filiz-contrast: #ffffff;
  --ion-color-filiz-contrast-rgb: 255, 255, 255;
  --ion-color-filiz-shade: #43882b;
  --ion-color-filiz-tint: #5ea446;

  /** secondary **/
  --ion-color-secondary: #718cac;
  --ion-color-secondary-rgb: 113, 140, 172;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #637b97;
  --ion-color-secondary-tint: #718cac;

  /** secondary-light **/
  --ion-color-secondary-light: #f2f4f5;
  --ion-color-secondary-light-rgb: 242, 244, 245;
  --ion-color-secondary-light-contrast: #718cac;
  --ion-color-secondary-light-contrast-rgb: 113, 140, 172;
  --ion-color-secondary-light-shade: #d5d7d8;
  --ion-color-secondary-light-tint: #f3f5f6;

  /** tertiary **/
  --ion-color-tertiary: #284460;
  --ion-color-tertiary-rgb: 40, 68, 96;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #233c54;
  --ion-color-tertiary-tint: #3e5770;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #d35353;
  --ion-color-danger-rgb: 211, 83, 83;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #ba4949;
  --ion-color-danger-tint: #d76464;

  /** dark **/
  --ion-color-dark: #434276;
  --ion-color-dark-rgb: 67, 66, 118;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #3b3a68;
  --ion-color-dark-tint: #565584;

  /** medium **/
  --ion-color-medium: #e6e6ef;
  --ion-color-medium-rgb: 230, 230, 239;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #cacad2;
  --ion-color-medium-tint: #e9e9f1;

  /** light **/
  --ion-color-light: #f2f4f5;
  --ion-color-light-rgb: 242, 244, 245;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d5d7d8;
  --ion-color-light-tint: #f3f5f6;

  // primary light
  --ion-color-primary-light: #8483b0;
  --ion-color-primary-light-rgb: 132, 131, 176;
  --ion-color-primary-light-contrast: #f3f3f7;
  --ion-color-primary-light-contrast-rgb: 243, 243, 247;
  --ion-color-primary-light-shade: #9a99ca;
  --ion-color-primary-light-tint: #cac9f3;

  /** Ultra Light **/
  --ion-color-ultra-light: #ffffff;
  --ion-color-ultra-light-rgb: 255, 255, 255;
  --ion-color-ultra-light-contrast: #000000;
  --ion-color-ultra-light-contrast-rgb: 0, 0, 0;
  --ion-color-ultra-light-shade: #e0e0e0;
  --ion-color-ultra-light-tint: #ffffff;

  /** White **/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  /** Black **/
  --ion-color-black: #000000;
  --ion-color-black-rgb: 0, 0, 0;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #1a1a1a;


  --ion-color-overlay: #f2f4f5;
  --ion-color-overlay-rgb: 242, 244, 245;
  --ion-color-overlay-contrast: #000000;
  --ion-color-overlay-contrast-rgb: 0, 0, 0;
  --ion-color-overlay-shade: #d5d7d8;
  --ion-color-overlay-tint: #f3f5f6;

  --ion-text-color: #000000;
  --ion-text-color-rgb: 0, 0, 0;
 /** Night Blue **/
 --ion-color-night-blue: #304659;
 --ion-color-night-blue-rgb: 48, 70, 89;
 --ion-color-night-blue-contrast: #ffffff;
 --ion-color-night-blue-contrast-rgb: 255, 255, 255;
 --ion-color-night-blue-shade: #2a3e4e;
 --ion-color-night-blue-tint: #45596a;


 --ion-background-color: #ffffff;;
 --ion-background-color-rgb:  255, 255, 25;
  /** Tab colors **/
  --ion-tab-bar-background: #ffffff;
  --ion-tab-bar-background-focused: #ffffff;
  --ion-tab-bar-border-color: #ffffff00;
  --ion-tab-bar-color: rgba(var(--ion-color-primary-rgb), 0.3);
  --ion-tab-bar-color-selected: var(--ion-color-primary);

  /** Toolbar Colors **/
  // --ion-toolbar-background: #e5e5e500;
  // --ion-toolbar-border-color: #e5e5e500;
  --ion-toolbar-background: var(--ion-background-color); //#e5e5e500;
  --ion-toolbar-border-color: #FF0000 // #e5e5e500;

  --ion-item-background: #ffffff;
  --ion-item-border-color: #ffffff;

  --ion-card-background: #ffffff;

  --ion-color-step-50: #dadada;
  --ion-color-step-100: #cecece;
  --ion-color-step-150: #c3c3c3;
  --ion-color-step-200: #b7b7b7;
  --ion-color-step-250: #acacac;
  --ion-color-step-300: #a0a0a0;
  --ion-color-step-350: #959595;
  --ion-color-step-400: #898989;
  --ion-color-step-450: #7e7e7e;
  --ion-color-step-500: #737373;
  --ion-color-step-550: #676767;
  --ion-color-step-600: #5c5c5c;
  --ion-color-step-650: #505050;
  --ion-color-step-700: #454545;
  --ion-color-step-750: #393939;
  --ion-color-step-800: #2e2e2e;
  --ion-color-step-850: #222222;
  --ion-color-step-900: #171717;
  --ion-color-step-950: #0b0b0b;

  --ion-color-tab-primary: #ffffff;
  --ion-color-tab-primary-rgb: 255, 255, 255;
  --ion-color-tab-primary-contrast: #000000;
  --ion-color-tab-primary-contrast-rgb: 0, 0, 0;
  --ion-color-tab-primary-shade: #e0e0e0;
  --ion-color-tab-primary-tint: #ffffff;

  --doktar-intl-phone-input-size: 35px;

  --doktar-intl-phone-input-color: #a9a9a9;

  // --doktar-intl-phone-input-bg-color: #ffffff;

  --doktar-intl-phone-input-thickness: 1px;

  --doktar-intl-phone-input-icon-color: #333333;
  --ion-color-gray: #edf1f8;
  --ion-color-gray-rgb: 237, 241, 248;
  --ion-color-gray-contrast: #7a828d;
  --ion-color-gray-contrast-rgb: 122, 130, 141;
  --ion-color-gray-shade: #d1d4da;
  --ion-color-gray-tint: #eff2f9;
}

.ion-color-gray {
  --ion-color-base: var(--ion-color-gray);
  --ion-color-base-rgb: var(--ion-color-gray-rgb);
  --ion-color-contrast: var(--ion-color-gray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gray-contrast-rgb);
  --ion-color-shade: var(--ion-color-gray-shade);
  --ion-color-tint: var(--ion-color-gray-tint);
}

.ion-color-tab-primary {
  --ion-color-base: var(--ion-color-tab-primary);
  --ion-color-base-rgb: var(--ion-color-tab-primary-rgb);
  --ion-color-contrast: var(--ion-color-tab-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tab-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-tab-primary-shade);
  --ion-color-tint: var(--ion-color-tab-primary-tint);
}

.ion-color-pesttrapp {
  --ion-color-base: var(--ion-color-pesttrapp);
  --ion-color-base-rgb: var(--ion-color-pesttrapp-rgb);
  --ion-color-contrast: var(--ion-color-pesttrapp-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pesttrapp-contrast-rgb);
  --ion-color-shade: var(--ion-color-pesttrapp-shade);
  --ion-color-tint: var(--ion-color-pesttrapp-tint);
}

.ion-color-primary-light {
  --ion-color-base: var(--ion-color-primary-light);
  --ion-color-base-rgb: var(--ion-color-primary-light-rgb);
  --ion-color-contrast: var(--ion-color-primary-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-light-shade);
  --ion-color-tint: var(--ion-color-primary-light-tint);
}

.ion-color-filiz {
  --ion-color-base: var(--ion-color-filiz);
  --ion-color-base-rgb: var(--ion-color-filiz-rgb);
  --ion-color-contrast: var(--ion-color-filiz-contrast);
  --ion-color-contrast-rgb: var(--ion-color-filiz-contrast-rgb);
  --ion-color-shade: var(--ion-color-filiz-shade);
  --ion-color-tint: var(--ion-color-filiz-tint);
}

.ion-color-secondary-light {
  --ion-color-base: var(--ion-color-secondary-light);
  --ion-color-base-rgb: var(--ion-color-secondary-light-rgb);
  --ion-color-contrast: var(--ion-color-secondary-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-secondary-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-secondary-light-shade);
  --ion-color-tint: var(--ion-color-secondary-light-tint);
}
.ion-color-ultra-light {
  --ion-color-base: var(--ion-color-ultra-light);
  --ion-color-base-rgb: var(--ion-color-ultra-light-rgb);
  --ion-color-contrast: var(--ion-color-ultra-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ultra-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-ultra-light-shade);
  --ion-color-tint: var(--ion-color-ultra-light-tint);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black-rgb);
  --ion-color-contrast: var(--ion-color-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-shade);
  --ion-color-tint: var(--ion-color-black-tint);
}

.ion-color-overlay {
  --ion-color-base: var(--ion-color-overlay);
  --ion-color-base-rgb: var(--ion-color-overlay-rgb);
  --ion-color-contrast: var(--ion-color-overlay-contrast);
  --ion-color-contrast-rgb: var(--ion-color-overlay-contrast-rgb);
  --ion-color-shade: var(--ion-color-overlay-shade);
  --ion-color-tint: var(--ion-color-overlay-tint);
}

// @media (prefers-color-scheme: dark) {
/*
   * Dark Colors
   * -------------------------------------------
   */

body.filiz {
  --ion-tab-bar-background: #ffffff;
  --ion-tab-bar-background-focused: #ffffff;
  --ion-tab-bar-border-color: #ffffff00;
  --ion-tab-bar-color: rgba(var(--ion-color-primary-rgb), 0.3);
  --ion-tab-bar-color-selected: var(--ion-color-primary);

  --ion-color-primary: #4c9a31;
  --ion-color-primary-rgb: 76, 154, 49;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #43882b;
  --ion-color-primary-tint: #5ea446;

  // primary light
  --ion-color-primary-light: #4c9a31;
  --ion-color-primary-light-rgb: 76, 154, 49;
  --ion-color-primary-light-contrast: #edf5ea;
  --ion-color-primary-light-contrast-rgb: 237, 245, 234;
  --ion-color-primary-light-shade: #fafff8;
  --ion-color-primary-light-tint: #fafff8;

  // pesttrapp
  --ion-color-pesttrapp: #8483b0;
  --ion-color-pesttrapp-rgb: 132, 131, 176;
  --ion-color-pesttrapp-contrast: #ffffff;
  --ion-color-pesttrapp-contrast-rgb: 255, 255, 255;
  --ion-color-pesttrapp-shade: #74739b;
  --ion-color-pesttrapp-tint: #908fb8;

  // filiz
  --ion-color-filiz: #4c9a31;
  --ion-color-filiz-rgb: 76, 154, 49;
  --ion-color-filiz-contrast: #ffffff;
  --ion-color-filiz-contrast-rgb: 255, 255, 255;
  --ion-color-filiz-shade: #43882b;
  --ion-color-filiz-tint: #5ea446;

  --ion-color-secondary: #718cac;
  --ion-color-secondary-rgb: 113, 140, 172;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #637b97;
  --ion-color-secondary-tint: #718cac;

  /** secondary-light **/
  --ion-color-secondary-light: #f2f4f5;
  --ion-color-secondary-light-rgb: 242, 244, 245;
  --ion-color-secondary-light-contrast: #718cac;
  --ion-color-secondary-light-contrast-rgb: 113, 140, 172;
  --ion-color-secondary-light-shade: #d5d7d8;
  --ion-color-secondary-light-tint: #f3f5f6;

  /** tertiary **/
  --ion-color-tertiary: #284460;
  --ion-color-tertiary-rgb: 40, 68, 96;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #233c54;
  --ion-color-tertiary-tint: #3e5770;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #d35353;
  --ion-color-danger-rgb: 211, 83, 83;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #ba4949;
  --ion-color-danger-tint: #d76464;


  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  /** dark **/
  --ion-color-dark: #36821c;
  --ion-color-dark-rgb: 54, 130, 28;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #307219;
  --ion-color-dark-tint: #4a8f33;

  /** medium **/
  --ion-color-medium: #e0ffd6;
  --ion-color-medium-rgb: 224, 255, 214;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #c5e0bc;
  --ion-color-medium-tint: #e3ffda;

  /** light **/
  --ion-color-light: #f2f4f5;
  --ion-color-light-rgb: 242, 244, 245;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d5d7d8;
  --ion-color-light-tint: #f3f5f6;

  --ion-color-overlay: #f2f4f5;
  --ion-color-overlay-rgb: 242, 244, 245;
  --ion-color-overlay-contrast: #000000;
  --ion-color-overlay-contrast-rgb: 0, 0, 0;
  --ion-color-overlay-shade: #d5d7d8;
  --ion-color-overlay-tint: #f3f5f6;

    /** Ultra Light **/
    --ion-color-ultra-light: #ffffff;
    --ion-color-ultra-light-rgb: 255, 255, 255;
    --ion-color-ultra-light-contrast: #000000;
    --ion-color-ultra-light-contrast-rgb: 0, 0, 0;
    --ion-color-ultra-light-shade: #e0e0e0;
    --ion-color-ultra-light-tint: #ffffff;
    
    --ion-background-color: #ffffff;;
    --ion-background-color-rgb:  255, 255, 25;

    --ion-toolbar-background: var(--ion-background-color); //#e5e5e500;
    --ion-toolbar-border-color: var(--ion-background-color); // #e5e5e500;
    

  // --ion-text-color: #ffffff;
  // --ion-text-color-rgb: 255, 255, 255;

  // --ion-backdrop-color: "";
  // --ion-backdrop-opacity: "";
  // --ion-overlay-background-color: "";

  // --ion-border-color: "";
  // --ion-box-shadow-color: "";

  /** Tab colors **/
  // --ion-tab-bar-background: #121212;
  // --ion-tab-bar-background-focused: #121212;
  // --ion-tab-bar-border-color: #121212;
  // --ion-tab-bar-color: var(--ion-color-medium);
  // --ion-tab-bar-color-selected: var(--ion-color-primary);

  /** Toolbar Colors **/
  // --ion-toolbar-background: #e5e5e500;
  // --ion-toolbar-border-color: #e5e5e500;
  // --ion-toolbar-color: "";
  // --ion-toolbar-segment-color: "";
  // --ion-toolbar-segment-color-checked: "";
  // --ion-toolbar-segment-background: "";
  // --ion-toolbar-segment-background-checked: "";
  // --ion-toolbar-segment-indicator-color: "";

  // --ion-item-background: #222428;
  // --ion-item-border-color: #222428;
  // --ion-item-color: #FFFFFF;

  // --ion-placeholder-color: "";

  // --ion-card-background: #1c1c1d;

  // --ion-color-tab-primary: #121212;
  // --ion-color-tab-primary-rgb: 18, 18, 18;
  // --ion-color-tab-primary-contrast: #ffffff;
  // --ion-color-tab-primary-contrast-rgb: 255, 255, 255;
  // --ion-color-tab-primary-shade: #16171a;
  // --ion-color-tab-primary-tint: #000000;
}

/*
   * iOS Dark Theme
   * -------------------------------------------
   */