
$constSafeareaInsetBottom: constant(safe-area-inset-bottom);
$constSafeareaInsetTop: constant(safe-area-inset-top);
$envSafeareaInsetBottom: env(safe-area-inset-bottom);
$envSafeareaInsetTop: env(safe-area-inset-top);

html {
    // --ion-safe-area-top: constant(safe-area-inset-top);
    // --ion-safe-area-bottom: constant(safe-area-inset-bottom);
    // --ion-safe-area-top: env(safe-area-inset-top);
    // --ion-safe-area-bottom: env(safe-area-inset-bottom);
}

$safeareaInsetBottom: var(--ion-safe-area-bottom);
$safeareaInsetTop: var(--ion-safe-area-top);
html.plt-android {
    ion-header:first-of-type {
        padding-top: 25px;
        background: var(--ion-background-color);
    }

    /**
    *   full screen appte, sayfanın en tepesinde konumlanacak fab btn için ek top değeri tanımlanır.
    * böylece status bardaki herhangi bir elementten korunmuş olur.
    */
    .ion-fab-md-top-padding {
        top: 30px !important;
    }

    app-modal-base {
        ion-header:first-of-type {
            padding-top: 0;
        }
    }
}
html.plt-ios {
    ion-header:first-of-type {
        // padding-top: 25px;
        padding-top: calc(constant(safe-area-inset-top) * 0.75);
        // padding-bottom: constant(safe-area-inset-bottom);
        padding-top: calc(env(safe-area-inset-top) * 0.75);
        // padding-bottom: env(safe-area-inset-bottom);
        background-color: var(--ion-background-color);
    }

    ion-tabs {
        padding-bottom: calc(constant(safe-area-inset-bottom) * 0.5);
        padding-bottom: calc(env(safe-area-inset-bottom) * 0.5);
    }

    /**
    *   full screen appte, sayfanın en tepesinde konumlanacak fab btn için ek top değeri tanımlanır.
    * böylece status bardaki herhangi bir elementten korunmuş olur.
    */
    .ion-fab-md-top-padding {
        top: calc(constant(safe-area-inset-top) + 0px) !important;
        top: calc(env(safe-area-inset-top) + 0px) !important;
    }

    orbit-modal-base {
        ion-header:first-of-type {
            padding-top: 0;
        }
    }


    .modal {

        &-fullscreen {
            
        }

        &-transparent {}
    }



}

ion-tab-bar {
    padding: 0 !important;
}

ion-header ion-toolbar:first-of-type {
    padding-top: 0 !important;
}

.safe-area {
    // &-vertical {
    //     margin-top: $safeareaInsetTop;
    //     margin-bottom: $safeareaInsetBottom;
    // }
}

*[shape="round"] {
    --border-radius: 10px;
    border-radius: 10px;
}

*[bg="none"] {
    --background: #00000000;
    background-color: #00000000;
}

.card-area {
    // padding-bottom: calc(30px + var(--ion-safe-area-bottom) + var(--ion-safe-area-top));
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow: scroll;
}

.custom-segment {
    border-radius: 20px;
    --background: var(--ion-color-overlay);

    ion-segment-button {
        --background-checked: #324f64;
        --background-focused: var(--ion-color-overlay);
        --background-focused-opacity: 1;
        --background-hover: var(--ion-color-overlay);
        --background-hover-opacity: 1;
        --border-radius: 20px;
        --border-style: solid;
        --border-width: 8px;
        --color: #324f64;
        --color-checked: white;
        --color-focused: var(--ion-color-overlay-contrast);
        --color-hover: var(--ion-color-overlay-contrast);
        --indicator-color: #324f64;
    }
}

.full {
    &-height {
        height: 100%;
    }

    &-width {
        width: 100%;
    }
}

.custom {
    margin-bottom: var(--ion-safe-area-bottom);
    margin-top: var(--ion-safe-area-top);

    &-ion {
        &-card {
            border-radius: 20px 20px 0px 0px;
        }
    }
}

.position {
    &-top {
        &-end {
            position: fixed;
            top: 20px;
            right: 20px;
        }
    }

    &-bottom {
        &-end {
            position: fixed;
            bottom: calc(20px + var(--ion-safe-area-bottom));
            right: 20px;
        }
    }

    &-relative {
        position: relative;
    }

    &-absolute {
        position: absolute;

        &-top {
            position: absolute;
            top: calc(20px + var(--ion-safe-area-top));

            &-end {
                position: absolute;
                top: calc(20px + var(--ion-safe-area-top));
                right: 20px;
            }
        }

        &-bottom {
            position: absolute;
            bottom: calc(20px + var(--ion-safe-area-bottom));

            &-end {
                position: absolute;
                bottom: calc(20px + var(--ion-safe-area-bottom));
                right: 20px;
            }
        }
    }
}

.item-has-focus,
.item-has-value {
    ion-label {
        margin-top: 10px !important;
    }
}

$base-z-index: 10;

@for $i from 0 through 100 {
    $value: $i * $base-z-index;

    .z-index-#{$value} {
        z-index: $value;
    }
}

ion-modal {
    &::part(handle) {
        width: 35px !important;
        opacity: 0.5 !important;
        background: #cecdf1 !important;
    }
}

.modal {
    --max-height: 95vh;

    &-fullscreen {
        --max-height: 100vh;
        --height: 100%;
        --width: 100%;
    }

    &-transparent {
        --backdrop-opacity: 0.7 !important;
        --background: rgba(0, 0, 0, 0);
    }

    &-height {
        &-60 {
            // --height: 60% !important;
            --min-height: 20%;
        }
    }

    &-position {
        &-bottom {
            align-items: flex-end;
        }

        &-center {
            --min-height: 10%;
            align-items: center;
            flex: 1;
            flex-shrink: 1;
            display: flex;
            justify-content: center;
        }
    }

    &-radius {
        --border-radius: 20px;
        // .modal-wrapper {
        //     border-radius: 25px !important;
        // }
    }

    &-half {
        --max-height: 75vh;
        --height: 100%;
        --width: 100%;
        display: flex;
        align-items: flex-end;
    }
   
    &-max-height {
        &-60{
            max-height: 60vh;
        }
    }
}

.example-modal {
    align-items: center;
    justify-content: center;

    &::part(content) {
        width: 80%;
        height: 50%;
    }
}

/**
    margin, padding
*/
$base-value: 5;

@for $i from 0 through 100 {
    $value: $base-value * $i;

    .p-all-#{$value} {
        padding: #{$value}px;
    }

    .p-horizontal-#{$value} {
        padding-left: #{$value}px;
        padding-right: #{$value}px;
    }

    .p-vertical-#{$value} {
        padding-top: #{$value}px;
        padding-bottom: #{$value}px;
    }

    .p-top-#{$value} {
        padding-top: #{$value}px;
    }

    .p-bottom-#{$value} {
        padding-bottom: #{$value}px;
    }

    .p-left-#{$value} {
        padding-left: #{$value}px;
    }

    .p-right-#{$value} {
        padding-right: #{$value}px;
    }

    .m-all-#{$value} {
        margin: #{$value}px;
    }

    .m-horizontal-#{$value} {
        margin-left: #{$value}px;
        margin-right: #{$value}px;
    }

    .m-vertical-#{$value} {
        margin-top: #{$value}px;
        margin-bottom: #{$value}px;
    }

    .m-top-#{$value} {
        margin-top: #{$value}px;
    }

    .m-bottom-#{$value} {
        margin-bottom: #{$value}px;
    }

    .m-left-#{$value} {
        margin-left: #{$value}px;
    }

    .m-right-#{$value} {
        margin-right: #{$value}px;
    }
}

.margin-auto {
    margin: auto;
}

@for $i from 0 through 100 {
    $value: $i;

    .p-all-#{$value} {
        padding: #{$value}px;
    }

    .p-horizontal-#{$value} {
        padding-left: #{$value}px;
        padding-right: #{$value}px;
    }

    .p-vertical-#{$value} {
        padding-top: #{$value}px;
        padding-bottom: #{$value}px;
    }

    .p-top-#{$value} {
        padding-top: #{$value}px;
    }

    .p-bottom-#{$value} {
        padding-bottom: #{$value}px;
    }

    .p-left-#{$value} {
        padding-left: #{$value}px;
    }

    .p-right-#{$value} {
        padding-right: #{$value}px;
    }

    .m-all-#{$value} {
        margin: #{$value}px;
    }

    .m-horizontal-#{$value} {
        margin-left: #{$value}px;
        margin-right: #{$value}px;
    }

    .m-vertical-#{$value} {
        margin-top: #{$value}px;
        margin-bottom: #{$value}px;
    }

    .m-top-#{$value} {
        margin-top: #{$value}px;
    }

    .m-bottom-#{$value} {
        margin-bottom: #{$value}px;
    }

    .m-left-#{$value} {
        margin-left: #{$value}px;
    }

    .m-right-#{$value} {
        margin-right: #{$value}px;
    }
}

@for $i from 0 through 35 {
    $font: $i;

    .font-size-#{$font} {
        font-size: #{$font}px !important;
    }
}

.opacity {
    &-1 {
        opacity: 0.1;
    }

    &-2 {
        opacity: 0.2;
    }

    &-3 {
        opacity: 0.3;
    }

    &-4 {
        opacity: 0.4;
    }

    &-5 {
        opacity: 0.5;
    }

    &-6 {
        opacity: 0.6;
    }

    &-7 {
        opacity: 0.7;
    }

    &-8 {
        opacity: 0.8;
    }

    &-9 {
        opacity: 0.9;
    }

    &-full {
        opacity: 1;
    }
}

$count: 300;

@while $count < 1000 {
    .font-weight-#{$count} {
        font-weight: ($count);
    }

    $count: $count + 100;
}

.box-shadow-none {
    box-shadow: none;
}

.text-center {
    text-align: center;
}
.text-end {
    text-align: end;
}

.text-left {
    text-align: left;
}

.item-61px {
    --min-height: 61px !important;
    --max-height: 61px !important;
    height: 61px !important;
    --inner-padding-end: 0px !important;
}

.form-error {
    font-size: 12px;
}

.ng-otp-input-wrapper {
    text-align: center !important;
}

.otp-input {
    height: 83px !important;
    width: 18.75vw !important;
    font-size: 30px !important;
    font-weight: 700;
    background-color: #ffffff;
    border: 1px solid #eeeeee !important;
    border-radius: 10px !important;

    // box-shadow: 0px 3px 0px rgba(141, 153, 174, 0.5);
    &:focus,
    &:active {
        outline-style: none !important;
        border-top: 1px solid #eeeeee !important;
        border-right: 1px solid #eeeeee !important;
        border-left: 1px solid #eeeeee !important;
        border-bottom: 2px solid var(--ion-color-secondary) !important;
    }
}

.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 1s linear;
}

.hidden-open {
    visibility: visible;
    opacity: 1;
}

.custom-item-detail-icon {
    --detail-icon-color: #252525 !important;
    --detail-icon-font-size: 25px !important;
    --detail-icon-opacity: 1 !important;
}

.button-common {
    height: 55px;
    width: 100%;
}

.field-item-invalid {
    border: 1px solid var(--ion-color-danger);
    --background: rgba(var(--ion-color-danger-rgb), 0.05);

    ion-label {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: var(--ion-color-danger) !important;
        margin-top: -8px;
        margin-inline: unset;
    }
}

.field-item-valid {
    --background: var(--ion-color-light);

    ion-label {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #9f9f9f !important;
        margin-top: -8px;
        margin-inline: unset;
    }
}

.float-right {
    float: right;
}

.log-card {
    background: white;
    border-radius: 15px;
    padding: 15px 10px;
    margin: 8px 16px;
}

.center-modal {
    --backdrop-opacity: 0.7;
    --border-radius: 15px;
    --height: 440px;
    --width: 90vw;
    color: black;
}

.image-modal {
    --height: 90vw;
    --width: 90vw;
    --backdrop-opacity: 0.7;
    --border-radius: 5px;
}

.center-modal-sticky {
    --backdrop-opacity: 0.7;
    --border-radius: 15px;
    --height: 400px;
    --width: 90vw;
    color: black;
}

::ng-deep {
    ion-select {
        .select-icon {
            display: none !important;
        }
    }
}

.my-class {
    font-size: 12px;
    font-weight: 700;
    padding: 0;
}

.fab-div {
    height: 28px;
    width: 28px;
    float: right;
    border-radius: 50%;
    background: #efefef;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.p-toolbar {
    background: white;
    border-bottom-left-radius: 20px;
    padding: 15px;
    border-bottom-right-radius: 20px;
    height: 67px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 20px 20px;
    background-color: #fff;
    padding: 15px;
    position: fixed;
    width: 100%;
    z-index: 9;
}

.profile-card {
    border-radius: 16px;
    padding: 10px;
    margin: 10px 20px;
    background: white;
}

ion-title {
    padding-inline: 0px;
}

// .bg {
//     &-default {
//         --background: #00000000;
//         background-color: #00000000;
//         -webkit-transition: --background 100ms linear;
//         -ms-transition: --background 100ms linear;
//         transition: --background 100ms linear;
//     }

//     &-opaque {
//         background-color: #00000050;
//         -webkit-transition: background-color 250ms linear;
//         -ms-transition: background-color 250ms linear;
//         transition: background-color 250ms linear;
//     }
// }

ion-segment-button {
    text-transform: none !important;
}

.farm-list-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
    display: block;
}

.marker {
    background-size: contain;
    width: 50px;
    height: 80px;
    background-position: top;
    background-repeat: no-repeat;
    // background-image: url(../assets/icon/marker.png);
    background-position: bottom;

    &V4 {
        // background-image: url(../assets/images/marker_v4.png);
    }
}

.map-marker {
    background-size: contain;
    width: 25px;
    height: 30px;
    background-position: top;
    background-repeat: no-repeat;
    background-position: bottom;

    // background-image: url(../assets/images/map-trap.png);
    &V4 {
        // background-image: url(../assets/images/marker_v4.png);
    }
}

.map-marker-two-device {
    background-size: contain;
    width: 50px;
    height: 31px;
    background-position: top;
    background-repeat: no-repeat;
    background-position: bottom;

    // background-image: url(../assets/images/map-trap.png);
    &V4 {
        // background-image: url(../assets/images/marker_v4.png);
    }
}

.bg {
    &-default {
        --background: #00000000;
        background-color: #00000000;
        -webkit-transition: --background 100ms linear;
        -ms-transition: --background 100ms linear;
        transition: --background 100ms linear;
    }

    &-opaque {
        // background-color: #0000003b;
        -webkit-transition: background-color 250ms linear;
        -ms-transition: background-color 250ms linear;
        transition: background-color 250ms linear;
    }
}

.break-spaces {
    white-space: break-spaces;
}

.icon-center {
    margin-top: 16px;
    font-size: 20px;
}

.form-input {
    font-size: 14px;
    --padding-top: 0;
    --padding-end: 0;
    margin-top: -15px;
}

.sheet-class {
    --width: 100% !important;

    .action-sheet-wrapper.sc-ion-action-sheet-md {
        max-width: unset !important;
    }

    .action-sheet-group {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    .action-sheet-title {
        color: var(--ion-color-primary) !important;
    }

    .action-sheet-icon {
        color: black !important;
    }
}

.demo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--ion-color-primary);
    padding: 10px;
    height: 65px;
}

.d-flex {
    display: flex;

    &.row {
        flex-direction: row;
    }

    &.column {
        flex-direction: column;
    }

    &.align-center {
        align-items: center;
    }

    &.justify {
        &-center {
            justify-content: center;
        }

        &-between {
            justify-content: space-between;
        }

        &-evenly {
            justify-content: space-evenly;
        }

        &-start {
            justify-content: flex-start;
        }

        &-end {
            justify-content: flex-end;
        }
    }

    .flex {
        &-grow {
            flex-grow: 1;
        }

        &-1 {
            flex: 1;
        }

        &-2 {
            flex: 2;
        }

        &-3 {
            flex: 3;
        }

        &-4 {
            flex: 4;
        }
    }
}

.d-contents {
    display: contents !important;
}

.d-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    &.col-3 {
        grid-template-columns: repeat(3, 1fr);
    }
}

.break-spaces {
    white-space: break-spaces !important;
}

.picker-wrapper.sc-ion-picker-ios {
    --height: 30vh;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    --width: 100vw;
    --background: #e5e5e5 !important;
    --background-rgb: rgb(229, 229, 229);
    --border-color: #e5e5e5;
}

.picker-opt.picker-opt-selected {
    font-size: 30px;
    font-weight: 400;
    opacity: 1;
}

.picker-opt {
    opacity: 0.5;
}

.blob {
    background: black;
    border-radius: 50%;
    border: 3px solid white;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    margin: 10px;
    height: 20px;
    width: 20px;
    // transform: scale(1);
    animation: pulse-black 2s infinite;
}

@keyframes pulse-black {
    0% {
        //   transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        //   transform: scale(1);
        box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
    }

    100% {
        //   transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.blob.yellow {
    background: rgba(255, 177, 66, 1);
    box-shadow: 0 0 0 0 rgba(255, 177, 66, 1);
    animation: pulse-yellow 2s infinite;
}

@keyframes pulse-yellow {
    0% {
        //   transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 177, 66, 0.7);
    }

    70% {
        //   transform: scale(1);
        box-shadow: 0 0 0 30px rgba(255, 177, 66, 0);
    }

    100% {
        //   transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
    }
}

.blob.blue {
    background: rgba(52, 172, 224, 1);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
    animation: pulse-blue 2s infinite;
}

@keyframes pulse-blue {
    0% {
        //   transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
    }

    70% {
        //   transform: scale(1);
        box-shadow: 0 0 0 30px rgba(52, 172, 224, 0);
    }

    100% {
        //   transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
    }
}

.farm-drawing-marker {
    width: 20px;
    height: 20px;
    background-color: var(--ion-color-light);
    border-radius: 100%;
    border-color: var(--ion-color-light);
    border-style: solid;
    border-width: 1px;

    &.first-marker {
        background-color: var(--ion-color-primary);
    }

    &.last-marker {
        background-color: var(--ion-color-primary) !important;
    }

    &:first-of-type {
        background-color: var(--ion-color-primary);
    }
}

@for $i from 1 through 40 {
    $value: $i * 5;

    .height {
        &-#{$value} {
            height: #{$value}px;

            &-percent {
                height: #{$value + "%"};
            }

            &-ion {
                --height: #{$value}px;

                &-percent {
                    --height: #{$value + "%"};
                }
            }
        }

        &-min-#{$value} {
            min-height: #{$value}px;
            height: #{$value}px;

            &-ion {
                --min-height: #{$value}px;
                --height: #{$value}px;
            }
        }
    }

    .width {
        &-#{$value} {
            width: #{$value}px;

            &-percent {
                --width: #{$value + "%"};
            }

            &-ion {
                --width: #{$value}px;

                &-percent {
                    --width: #{$value + "%"};
                }
            }
        }

        &-min-#{$value} {
            min-width: #{$value}px;
            width: #{$value}px;

            &-ion {
                --min-width: #{$value}px;
                --width: #{$value}px;
            }
        }
    }
}

span,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    &[color="primary"] {
        color: var(--ion-color-primary);
    }

    &[color="secondary"] {
        color: var(--ion-color-secondary);
    }

    &[color="tertiary"] {
        color: var(--ion-color-tertiary);
    }

    &[color="success"] {
        color: var(--ion-color-success);
    }

    &[color="warning"] {
        color: var(--ion-color-warning);
    }

    &[color="danger"] {
        color: var(--ion-color-danger);
    }

    &[color="dark"] {
        color: var(--ion-color-dark);
    }

    &[color="medium-dark"] {
        color: var(--ion-color-medium-dark);
    }

    &[color="medium"] {
        color: var(--ion-color-medium);
    }

    &[color="medium-light"] {
        color: var(--ion-color-medium-light);
    }

    &[color="light"] {
        color: var(--ion-color-light);
    }

    &[color="white"] {
        color: var(--ion-color-white);
    }

    &[color="black"] {
        color: var(--ion-color-black);
    }
}

ion-searchbar {
    --background: var(--ion-color-ultra-light) !important;
    --border-radius: 8px !important;
    --box-shadow: 1px !important;
    --cancel-button-color: var(--ion-color-dark) !important;
    --clear-button-color: var(--ion-color-dark) !important;
    --color: var(--ion-color-dark) !important;
    --icon-color: #535353 !important;
    --placeholder-color: #535353 !important;
    --placeholder-font-style: initial !important;
    --placeholder-font-weight: initial !important;
    --placeholder-opacity: 0.8 !important;

    &.custom {
        .searchbar-input-container {
            height: 55px !important;
        }

        .searchbar-input {
            padding-inline-start: 32px !important;
        }

        .searchbar-search-icon {
            margin-inline-start: 6px !important;
            width: 18px;
        }
    }
}

.white-space-nowrap {
    white-space: nowrap;
}
ion-fab-button[fill="clear"] {
    --background: #00000000 !important;
    --background-activated: #00000000 !important;
    --background-hover: #00000000 !important;
    --border-radius: 15px;
    --box-shadow: unset;
}

.cursor-pointer {
    cursor: pointer !important;
}

.display-flex {
    display: flex !important;
}
.walkthrough {
    &-selectedElement {
      border: 4px solid var(--ion-color-secondary);
      border-radius: 10px;
    }
  }
